<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('subaccountlist_xq')" id="fzcolor">基本信息</span>
      <span @click="toTypeUrl('add_subaccountlist_shop')">分账商户信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">基本信息</h5>
    </div>
    <div class="input_con">
      <ul class="inputBox">
        <li v-if="formData.mchType != 'PERSONAL'"> <!-- 企业 -->
          <span><i>*</i>分账商户全称</span>
          <el-input v-if="approveImg != 'NOT_PASSED' && approveImg != 'NOT_UPLOAD' && approveImg != 'PASSED'"
            class="inputs"
            v-model="formData.allocMchName"
            maxlength="128"
            placeholder="输入分账商户全称"
            tabindex="1"
            :disabled="pageType == 'edit'"
          ></el-input>  
          <el-input v-else
            class="inputs"
            v-model="formData.allocMchName"
            maxlength="128"
            placeholder="输入分账商户全称"
            tabindex="1"
          ></el-input>  
        </li>
        <li v-if="formData.mchType == 'PERSONAL'"> <!-- 个人 -->
          <span><i>*</i>分账商户全称</span>
          <el-input
            class="inputs"
            v-model="formData.allocMchName"
            maxlength="128"
            placeholder="输入分账商户全称"
            tabindex="1"
          ></el-input>   
        </li>
        <li>
          <span><i>*</i>所属商户</span>
          <el-select
            class="inputs"
            v-model="formData.mchId"
            placeholder="选择所属商户"
            tabindex="2"
            :disabled="pageType == 'edit' ? true : false"
          >
            <el-option
              v-for="(v, i) in mchDrop"
              :key="i"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
        </li>
        <li style="position: relative;">
          <span><i>*</i>分账商户登录名</span>
          <el-input
            class="inputs"
            v-model="formData.loginName"
            maxlength="128"
            placeholder="输入分账商户登录名"
            tabindex="4"
            :disabled="pageType == 'edit'"
            @focus="focusTip()"
            @blur="blurTip()"  
          ></el-input>
          <div v-show="focusTitShow" style="position: absolute;top: 42px;left: 140px;font-size: 12px;color: #48b8b6;">邮箱地址或者自定义的数字字母，系统中具有唯一性校验</div>
        </li>
        <li>
          <span>分账商户简称</span>
          <el-input
            class="inputs"
            v-model="formData.mchShortName"
            maxlength="128"
            placeholder="输入分账商户简称"
            tabindex="3"
            :disabled="pageType == 'edit'"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>分账商户类型</span>
          <el-select
            class="inputs"
            v-model="formData.mchType"
            placeholder="选择分账商户类型"
            tabindex="5"
            :disabled="pageType == 'edit' ? true : false"
          >
            <el-option label="个人" value="PERSONAL"></el-option>
            <el-option
              label="个体工商户"
              value="INDIVIDUAL_BUSINESS"
            ></el-option>
            <el-option label="企业" value="ENTERPRISE"></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>联系人</span>
          <el-input
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="6"
          ></el-input>
        </li>
        <li>
          <span><i>*</i>联系人手机</span>
          <el-input
            class="inputs"
            v-model="formData.contactTel"
            maxlength="11"
            placeholder="输入联系人手机"
            tabindex="7"
          ></el-input>
        </li>
        <li> <!-- v-if="formData.mchType != 'PERSONAL'" -->
          <span><i>*</i>法人姓名</span>
          <el-input v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD' && approveImg != 'PASSED'"
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="8"
            :disabled="pageType == 'edit'"
          ></el-input>
          <el-input v-else
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="8"
          ></el-input>
        </li>

        <!-- <li v-if="formData.mchType == 'PERSONAL'">
          <span><i>*</i>法人姓名</span>
          <el-input v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD'"
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="8"
            :disabled="pageType == 'edit'"
          ></el-input>
        </li> -->   

        <li v-if="formData.mchType != 'PERSONAL'"> <!--企业-->
          <span><i>*</i>法人身份证号码</span>
          <el-input v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD' && approveImg != 'PASSED'"
            class="inputs"
            v-model="formData.legalPersonNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="9"
            :disabled="pageType == 'edit'"
          ></el-input>
          <el-input v-else
            class="inputs"
            v-model="formData.legalPersonNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="9"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'PERSONAL'"> <!--个人-->
          <span><i>*</i>法人身份证号码</span>
          <el-input v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD'"
            class="inputs"
            v-model="formData.legalPersonNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="9"
            :disabled="pageType == 'edit'"
          ></el-input>
          <el-input v-else
            class="inputs"
            v-model="formData.legalPersonNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="9"
          ></el-input>
        </li>        

        <li>
          <span><i>*</i>法人手机号码</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonPhone"
            maxlength="11"
            placeholder="输入法人手机号码"
            tabindex="10"
            :disabled="pageType == 'edit'"
          ></el-input>
        </li>
        <!-- <li v-if="formData.mchType == 'PERSONAL'">
          <span><i>*</i>法人身份证号码</span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="9"
          ></el-input>
        </li> -->
 
        <li>
          <span>法人证件有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.legalPersonCemEndDate"
              :picker-options="PersonOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li v-if="formData.mchType == 'PERSONAL'">
          <span
            ><i v-show="formData.mchType != 'PERSONAL'">*</i>统一社会信用代码</span
          >
          <el-input
            class="inputs"
            v-model="formData.licenceNo"
            maxlength="128"
            placeholder="输入统一社会信用代码"
            tabindex="13"
          ></el-input>
        </li>
        <li v-if="formData.mchType != 'PERSONAL'">
          <span
            ><i v-show="formData.mchType != 'PERSONAL'">*</i>统一社会信用代码</span
          >
          <el-input  v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD'"
            class="inputs"
            v-model="formData.licenceNo"
            maxlength="128"
            placeholder="输入统一社会信用代码"
            tabindex="13"
            :disabled="pageType == 'edit'"
          ></el-input>
          <el-input v-else
            class="inputs"
            v-model="formData.licenceNo"
            maxlength="128"
            placeholder="输入统一社会信用代码"
            tabindex="13"
          ></el-input>
        </li>
        <li>
          <span>营业执照有效期</span>
          <div class="inputLine">
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceStartDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="开始日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">至</span>
            <el-date-picker
              class="inputs pick"
              v-model="formData.licenceEndDate"
              :picker-options="licenceOptions"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li>
          <span>经营范围</span>
          <el-input
            class="inputs"
            v-model="formData.businessScope"
            maxlength="128"
            placeholder="输入经营范围"
            tabindex="16"
          ></el-input>
        </li>
        <li>
          <span><i v-show="formData.mchType != 'PERSONAL'">*</i>营业地址</span>
          <el-input
            class="inputs"
            v-model="formData.businessAddress"
            maxlength="128"
            placeholder="输入营业地址"
            tabindex="17"
          ></el-input>
        </li>
        <li>
          <span>异步通知地址</span>
          <el-input
            class="inputs"
            v-model="formData.notifyUrl"
            maxlength="128"
            placeholder="输入异步通知地址"
            tabindex="18"
          ></el-input>
        </li>
      </ul>
    </div>
    <div class="upload_ph" v-if="false">
      <div class="uploader1">
        <h4><i v-show="formData.mchType != 'PERSONAL'">*</i>营业执照</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licencePictureSuccess"
        >
          <img
            v-if="formData.licencePicture"
            :src="formData.licencePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人证件正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" @click.stop="formData.idcardNormalPicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人证件反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i v-show="formData.mchType != 'PERSONAL'">*</i>开户许可证</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="accountOpeningLicenseSuccess"
        >
          <img
            v-if="formData.accountOpeningLicense"
            :src="formData.accountOpeningLicense"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.accountOpeningLicense" class="avatar-uploader-span" @click.stop="formData.accountOpeningLicense = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4>其他类型图片1</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="otherPicture1Success"
        >
          <img
            v-if="formData.otherPicture1"
            :src="formData.otherPicture1"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.otherPicture1" class="avatar-uploader-span" @click.stop="formData.otherPicture1 = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4>其他类型图片2</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="otherPicture2Success"
        >
          <img
            v-if="formData.otherPicture2"
            :src="formData.otherPicture2"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.otherPicture2" class="avatar-uploader-span" @click.stop="formData.otherPicture2 = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="desc">
      <h5 class="jsxx">结算信息</h5>
    </div>    
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 171px">
            <p>账户名</p>
          </td>
          <td style="width: 163px">
            <p>账号</p>
          </td>
          <td style="width: 95px">
            <p>联行号</p>
          </td>
          <td style="width: 269px">
            <p>账户类型</p>
          </td>
          <td style="width: 95px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in settlementsTabData" :key="i">
          <td style="width: 171px">
            <p>{{ v.accountName }}</p>
          </td>
          <td style="width: 163px">
            <p>{{ v.accountNo }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ v.interBranchesNo }}</p>
          </td>
          <td style="width: 269px">
            <p>{{ accountType[v.accountType] }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="editAccountTab(v, i)">编辑</i>
              <!-- <i class="lianjie" @click="delAccountTab(i,v)">删除</i> -->
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6
      class="xzjsxx"
      @click="addAccountTab"
      v-if="settlementsTabData.length < 1"
    >
      <span>+</span> 新增结算信息
    </h6>
    <div class="desc">
      <h5 class="jsxx">结算周期</h5>
    </div> 
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>结算方式</p>
          </td>
          <td>
            <p>结算周期类型</p>
          </td>
          <td>
            <p>结算周期</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in productsTabData" :key="i">
          <td style="width: 171px">
            <p>{{ settleType[v.settleType] }}</p>
          </td>
          <td style="width: 163px">
            <p>{{ cycleType[v.cycleType] }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ v.settleDay }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="editProductsTab(v, i)">编辑</i>
              <!-- <i class="lianjie" @click="delProductsTab(i,v)">删除</i> -->
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6
      class="xzjsxx"
      @click="addProductsTab"
      v-if="productsTabData.length < 1"
    >
      <span>+</span> 新增结算周期
    </h6>
    <div class="bottom" v-if="pageType == 'edit'">
      <el-button class="back" type="primary" @click="$router.go(-1)"
        >返回</el-button
      >
      <el-button @click="onSubmit" class="next" type="primary">保存</el-button>
    </div>
    <div class="bottom" v-else>
      <el-button class="back" type="primary" @click="$router.go(-1)"
        >返回</el-button
      >
      <el-button @click="onAllSubmit" class="next" type="primary"
        >保存</el-button
      >
    </div>
    <el-dialog title="结算账户" :visible.sync="overAccountShow" width="490px">
      <ul class="overAccount_ul">
        <li class="overAccount_ul_left">
          <div class="overAccount_ul_title"><i>*</i>账户类型</div>
          <el-select
            v-model="accountFormData.accountType"
            placeholder="选择账户类型"
            tabindex="1"
          >
            <el-option
              v-for="(v, i) in accountType"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>账户</div>
          <el-input
            v-model="accountFormData.accountNo"
            maxlength="128"
            placeholder="输入账户"
            tabindex="3"
          ></el-input>
        </li>
        <li class="overAccount_ul-right">
          <div class="overAccount_ul_title"><i>*</i>账户名</div>
          <el-input
            v-model="accountFormData.accountName"
            maxlength="128"
            placeholder="输入账户名"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title">
            <i v-show="accountFormData.accountType == 'COMPANY'">*</i>联行号
          </div>
          <el-input
            v-model="accountFormData.interBranchesNo"
            maxlength="128"
            placeholder="输入联行号"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="sureAccountAdd" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="editIndex === 0 ? '修改结算周期' : '新增结算周期'"
      :visible.sync="userPayShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="overAccount_ul_left">
          <div class="overAccount_ul_title"><i>*</i>结算方式</div>
          <el-select
            v-model="productsformData.settleType"
            placeholder="选择结算方式"
            tabindex="1"
          >
            <el-option
              v-for="v in settleDrop"
              :key="v.value"
              :label="v.title"
              :value="v.value"
              :disabled="v.disabled"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算周期</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="productsformData.settleDay"
            min="1"
            placeholder="输入结算周期"
            tabindex="3"
          ></el-input>
        </li>
        <li class="overAccount_ul-right">
          <div class="overAccount_ul_title"><i>*</i>结算周期类型</div>
          <el-select
            v-model="productsformData.cycleType"
            placeholder="选择结算周期类型"
            tabindex="2"
          >
            <el-option label="工作日(T)" value="WORK_DAY"></el-option>
            <el-option label="自然日(D)" value="NATURE_DAY"></el-option>
            <el-option label="月结日" value="MONTH_DAY"></el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureProductsAdd" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
import { merchantsDrop } from "@/api/common.js";
import * as imageConversion from "image-conversion";
import {
  getAllocmerchantsDetail,
  setAllocmerchants,
  setSettlements,
  delSettlements,
  setProducts,
  delProducts,
  addAllocmerchants,
} from "@/api/user/subaccount.js";
import { getProducts } from "@/api/user/shopManager.js";
var legalPersonCemEndDate = null,
  licenceEndDate = null;
export default {
  data() {
    return {
      formData: {
        mchType: "", //true	分账商户类型
        allocMchName: "", //	true	分账商户名称
        mchShortName: "", //	true	分账商户简称
        mchId: "", //true	所属商户ID
        loginName: "", //	true	分账商户标识
        contactName: "", //	true	联系人
        contactTel: "", //	true	联系电话
        legalPersonName: "", //	true	法人姓名
        legalPersonNo: "", //	true	身份证号码（法人或联系人）
        legalPersonPhone: "", //	true	法人手机号码
        legalPersonCemStartDate: "", //	true	法人证件开始日期，格式yyyy-MM-dd
        legalPersonCemEndDate: "", //	true	法人证件结束日期，格式yyyy-MM-dd，特殊值forever表示长期
        licenceNo: "", //	true	商户证件编号
        licenceStartDate: "", //	true	营业执照开始日期，格式yyyy-MM-dd
        licenceEndDate: "", //	true	营业执照结束日期，格式yyyy-MM-dd，特殊值forever表示长期
        businessScope: "", //	true	经营范围
        businessAddress: "", //	true	经营地址
        notifyUrl: "", //	true	异步通知地址
        idcardNormalPicture: "", //	true	法人身份证正面照片URL
        idcardReversePicture: "", //	true	法人身份证反面照片URL
        licencePicture: "", //	true	营业执照图片URL
        accountOpeningLicense: "", //true 开户许可证图片URL
        otherPicture1: "",
        otherPicture2: "",
      },
      mchDrop: [], //商户下拉
      uploadUrl: URL_API.upload,
      PersonOptions: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              legalPersonCemEndDate = "forever";
            },
          },
        ],
      },
      licenceOptions: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              licenceEndDate = "forever";
            },
          },
        ],
      },
      pageType: "", //页面状态
      settlementsTabData: [], // 结算信息
      accountFormData: {
        accountNo: "", //true	结算账户
        accountName: "", //true	结算账户名称
        interBranchesNo: "", //false	联行号（账户类型为企业时必填）
        accountType: "", //true	账户类型
      },
      overAccountShow: false, // 结算账户弹窗展示
      editIndex: "",
      accountType: {
        COMPANY: "企业",
        PERSONAL: "个人",
      },
      userPayShow: false, //新增支付展示
      productsformData: {
        settleType: "",
        cycleType: "",
        settleDay: "",
      },
      settleDrop: [
        // {
        //   title: "自动结算",
        //   value: "AUTO",
        //   disabled: false,
        // },
        // {
        //   title: "手工结算",
        //   value: "MANUAL",
        //   disabled: false,
        // },
        // {
        //   title: "手工即时结算",
        //   value: "MANUAL_INSTANT",
        //   disabled: false,
        // },
      ],
      settleType: {
        AUTO: "自动结算",
        MANUAL: "手工结算",
        MANUAL_INSTANT: "手工即时结算",
      },
      cycleType: {
        WORK_DAY: "工作日",
        NATURE_DAY: "自然日",
        MONTH_DAY: "月结日",
      },
      productsTabData: [],
      approveImg:"",
      mchTypeImg:"",
      buttonFlag:false,
      focusTitShow:false,
    };
  },
  computed: {
    ...mapState({
      subaccountDetail: "user_subaccountDetail",
    }),
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.getDetail();
      this.pageType = "edit";
    } else {
      if (this.subaccountDetail) {
        this.formData = this.subaccountDetail.mchDetail;
        this.settlementsTabData = this.subaccountDetail.settlements;
        this.productsTabData = this.subaccountDetail.products;
        this.mchTypeImg = this.subaccountDetail.mchDetail.mchType;
        if (this.formData.legalPersonCemEndDate == "forever") {
          legalPersonCemEndDate = "forever";
          this.formData.legalPersonCemEndDate = "2999-01-01";
        }
        if (this.formData.licenceEndDate == "forever") {
          licenceEndDate = "forever";
          this.formData.licenceEndDate = "2999-01-01";
        }
      }
    }
    this.getDrop();
  },
  methods: {
    ...mapMutations({
      setSubaccount: "user_setSubaccount",
    }),
    //获取详情
    getDetail() {
      // approveStatus == 'PASSED' 图片审核通过
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res) {
          console.log(res)
          this.formData = res.resultData.mchDetail;
          this.settlementsTabData = res.resultData.settlements;
          this.productsTabData = res.resultData.products;
          console.log(res.resultData.thirdAccounts,"----------------")

          this.approveImg = res.resultData.thirdAccounts[0].approveStatus;//图片审核状态

          console.log(this.approveImg)
          if (this.formData.legalPersonCemEndDate == "forever") {
            legalPersonCemEndDate = "forever";
            this.formData.legalPersonCemEndDate = "2999-01-01";
          }
          if (this.formData.licenceEndDate == "forever") {
            licenceEndDate = "forever";
            this.formData.licenceEndDate = "2999-01-01";
          }
        }
      });
    },
    // 获取下拉菜单
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 商户证件上传
    licencePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      }
    },
    // 身份证正面照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      }
    },
    // 身份证反面照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      }
    },
    // 其他类型图片1上传
    otherPicture1Success(res) {
      if (res.resultStatus) {
        this.formData.otherPicture1 = res.resultData.fileLink;
      }
    },
    // 其他类型图片2上传
    otherPicture2Success(res) {
      if (res.resultStatus) {
        this.formData.otherPicture2 = res.resultData.fileLink;
      }
    },
    // 表单规则
    roles() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.allocMchName) {
        this.$message.error("分账商户全称必填");
        return false;
      }
      if (!data.mchId) {
        this.$message.error("所属商户必选");
        return false;
      }
      if (!data.loginName) {
        this.$message.error("分账商户登录名必填");
        return false;
      }
      if (!data.mchType) {
        this.$message.error("分账商户类型必选");
        return false;
      }
      if (!data.contactName) {
        this.$message.error("联系人必填");
        return false;
      }
      if (!data.contactTel) {
        this.$message.error("联系人手机必填");
        return false;
      }
      if (
        !this.$route.query.id &&
        !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.contactTel)
      ) {
        this.$message.error("请正确填写手机号码");
        return;
      }
      if (!data.legalPersonName) {
        this.$message.error("法人姓名必填");
        return false;
      }
      if (!data.legalPersonNo) {
        this.$message.error("法人身份证号码必填");
        return false;
      }
      if (!data.legalPersonPhone) {
        this.$message.error("法人手机号码必填");
        return false;
      }
      if (
        !this.$route.query.id &&
        !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.legalPersonPhone)
      ) {
        this.$message.error("请正确填写手机号码");
        return;
      }
      if (!data.licenceNo && data.mchType != "PERSONAL") {
        this.$message.error("统一社会信用代码必填");
        return false;
      }
      if (!data.businessAddress && data.mchType != "PERSONAL") {
        this.$message.error("营业地址必填");
        return false;
      }
      return true;
    },
    // 页面跳转
    toUrl(name) {
      if (!this.roles()) {
        return;
      }
      this.$router.push({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
    // 添加结算账户展示
    addAccountTab() {
      this.accountFormData = {
        accountNo: "",
        accountName: "",
        interBranchesNo: "",
        accountType: "",
      };
      this.editIndex = null;
      this.overAccountShow = true;
    },
    // 修改结算账户
    editAccountTab(formData, index) {
      this.accountFormData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.overAccountShow = true;
    },
    //删除结算账户
    delAccountTab(index, value) {
      this.$confirm("此操作将删除该结算账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delSettlements(this.$route.query.id, value.settlementId).then(
              (res) => {
                if (res) {
                  this.getDetail();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              }
            );
          } else {
            this.settlementsTabData.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算账户
    sureAccountAdd() {
      let data = JSON.parse(JSON.stringify(this.accountFormData));
      if (!data.accountType) {
        this.$message.error("账户类型必选");
        return false;
      }
      if (!data.accountNo) {
        this.$message.error("结算账户必填");
        return false;
      }
      if (!data.accountName) {
        this.$message.error("结算账户名称必填");
        return false;
      }
      if (data.accountType === "COMPANY") {
        if (!data.interBranchesNo) {
          this.$message.error("联行号必填");
          return false;
        }
      }
      this.buttonFlag = true;
      if (this.$route.query.pageType == "edit") {
        data.settlementId = data.settlementId ? data.settlementId : 0;
        this.settlementsTabData[0] = data;
        this.$message.success("保存成功");
        this.overAccountShow = false;
      } else {
        if (this.editIndex !== null) {
          this.settlementsTabData[this.editIndex] = data;
        } else {
          this.settlementsTabData.push(data);
        }
        this.$message.success("保存成功");
        this.overAccountShow = false;
      }
      setTimeout(()=>{
        this.buttonFlag = false;
      },2000)
    },
    // 添加结算周期展示
    addProductsTab() {
      console.log("点击新增结算周期时", this.formData);
      // return
      this.productsformData = {
        settleType: "",
        cycleType: "",
        settleDay: "",
      };
      this.editIndex = null;
      this.settleDrop = [];
      if (this.formData.mchId) {
        this.getSettlementMethod(this.formData.mchId);
      } else {
        this.$message.error("请先选择所属商户");
      }
      // this.productsTabData.forEach((v) => {
      //   this.settleDrop.forEach((val) => {
      //     if (val.value == v.settleType) {
      //       val.disabled = true;
      //     }
      //   });
      // });
    },
    // 修改结算周期
    editProductsTab(formData, index) {
      this.productsformData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.settleDrop = [];
      this.getSettlementMethod(this.formData.mchId);
    },
    // 根据所属商户id获取结算方式
    getSettlementMethod(mchId) {
      this.settleDrop = [];
      getProducts(mchId).then((res) => {
        if (res.resultStatus) {
          if (res.resultData.allocProducts.length > 0) {
            res.resultData.allocProducts.map((obj) => {
              let value = {
                title: this.settleType[obj.settleType],
                value: obj.settleType,
                disabled: false,
              };
              this.settleDrop.push(value);
            });

            let map = new Map();
            for (let item of this.settleDrop) {
              if (!map.has(item.value)) {
                map.set(item.value, item);
              };
            };
            this.settleDrop = [...map.values()];
            
            this.userPayShow = true;
          } else {
            this.$message.error("所属商户暂无结算方式");
          }
        }
      });
    },
    //删除结算周期
    delProductsTab(index, value) {
      this.$confirm("此操作将删除该结算周期, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.$route.query.pageType == "edit") {
            delProducts(this.$route.query.id, value.settleType).then((res) => {
              if (res) {
                this.getDetail();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          } else {
            this.productsTabData.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算周期
    sureProductsAdd() {
      let data = JSON.parse(JSON.stringify(this.productsformData));
      if (!data.settleType) {
        this.$message.error("结算方式必选");
        return false;
      }
      if (!data.cycleType) {
        this.$message.error("周期类型必选");
        return false;
      }
      if (!data.settleDay) {
        this.$message.error("结算周期必填");
        return false;
      }
      if (!/^[1-9]\d*$/.test(data.settleDay)) {
        this.$message.error("结算周期为正整数");
        return false;
      }
      if (
        data.cycleType == "MONTH_DAY" &&
        (+data.settleDay < 1 || +data.settleDay > 28)
      ) {
        this.$message.error("结算周期范围（1-28）");
        return false;
      }
      this.buttonFlag = true;
      if (this.$route.query.pageType == "edit") {
        this.productsTabData[0] = data;
        this.$message.success("保存成功");
        this.userPayShow = false;
      } else {
        if (this.editIndex !== null) {
          this.productsTabData[this.editIndex] = data;
        } else {
          this.productsTabData.push(data);
        }
        this.$message.success("保存成功");
        this.userPayShow = false;
      }
      setTimeout(()=>{
        this.buttonFlag = false;
      },2000)
    },
    onAllSubmit() {
      if (!this.roles()) {
        return;
      }
      if (this.settlementsTabData.length <= 0) {
        this.$message.error("结算账户必填");
        return false;
      }
      if (this.productsTabData.length <= 0) {
        this.$message.error("结算周期必填");
        return false;
      }
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (formData.legalPersonCemEndDate == "2999-01-01" && legalPersonCemEndDate == "forever" ) {
        formData.legalPersonCemEndDate = "forever";
      }
      if (formData.licenceEndDate == "2999-01-01" && licenceEndDate == "forever") {
        formData.licenceEndDate = "forever";
      }
      delete formData.createTime;
      delete formData.updateTime;
      let data = {
        mchDetail: formData,
        settlements: this.settlementsTabData,
        products: this.productsTabData,
      };
      this.setSubaccount(data);
      addAllocmerchants(this.subaccountDetail).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.setSubaccount(null);
          this.$router.push({
            name: "subaccountlist_shlb",
          });
        }
      });
    },
    //保存
    onSubmit() {
      if (!this.roles()) {
        return;
      }
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (formData.legalPersonCemEndDate == "2999-01-01" && legalPersonCemEndDate == "forever" ) {
        formData.legalPersonCemEndDate = "forever";
      }
      if (formData.licenceEndDate == "2999-01-01" && licenceEndDate == "forever") {
        formData.licenceEndDate = "forever";
      }
      delete formData.createTime;
      delete formData.updateTime;
      let data = {
        mchDetail: formData,
        settlements: this.settlementsTabData,
        products: this.productsTabData,
      };
      setAllocmerchants(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.$router.go(-1);
        }
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片支持jpg/jpeg/png/bpm格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
    //分账商户登录名 聚焦提示
    focusTip(){
      this.focusTitShow = true;
    },
    blurTip(){
      this.focusTitShow = false;
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (
        formData.legalPersonCemEndDate == "2999-01-01" || legalPersonCemEndDate == "forever"
      ) {
        formData.legalPersonCemEndDate = "forever";
      }
      if (formData.licenceEndDate == "2999-01-01" || licenceEndDate == "forever") {
        formData.licenceEndDate = "forever";
      }
      let data = {
        mchDetail: formData,
        settlements: this.settlementsTabData,
        products: this.productsTabData,
      };
      this.setSubaccount(data);
    }
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";
.tab1{
  min-height: auto !important;
}
.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
}

.bottom {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}

.djsc {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
}

.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  height: 180px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: #48b8b6;
  padding-right: 3px;
  font-family: "Microsoft YaHei";
}

.uploader2 {
  margin-left: 30px;
  margin-right: 19px;
  float: left;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: #48b8b6;
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}
.ul_right {
  float: right;
  height: 100%;
  text-align: right;
  margin-right: 28px;
}

.ul_left {
  float: left;
  width: 492px;
  height: 100%;
  text-align: right;
}

.ul_left i,
.ul_right i {
  color: #48b8b6;
  padding-right: 3px;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.lianjie {
  color: #008aff;
  padding: 0 5px 0 5px;
  cursor: pointer;
}
</style>
